import React from 'react';
import { graphql } from 'gatsby';
import { Query } from 'react-apollo';
import tw from 'tailwind.macro';
import styled, { css } from 'styled-components';
import gql from 'graphql-tag';
import logger from 'utils/logger';
import Fade from 'react-reveal/Fade';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';

import { Container, Row } from 'components/GridSystem';
import { CallToActionPreFooter } from 'components/CallToActions';

import { Button } from 'components/Elements';

import {
  IntroService,
  TextIntro
} from 'components/Sections';
import { CaseCard } from 'components/Cards';

const AreasIntro = styled.div`
  ${tw`
    pt-6
    pb-4
    border-0
    border-t-2
    border-solid
    border-gray-200
    xl:px-1/24
    xl:mx-1/24
  `};
  h1 {
    ${tw`
      font-serif
      tracking-tight
    `};
  }
  p {
    ${tw`
      font-light
      tracking-tight
      leading-fit
      text-gray-400
      xl:w-1/2
      xl:text-2xl
    `};
  }
`;
const CasesIntro = styled.div`
  ${tw`
    pt-6
    pb-4
    border-0
    border-t-2
    border-solid
    border-gray-200
    xl:px-1/24
    xl:mx-1/24
  `};
  h1 {
    ${tw`
      font-serif
      tracking-tight
    `};
  }
  p {
    ${tw`
      font-light
      tracking-tight
      leading-fit
      text-gray-400
      xl:w-1/2
      xl:text-2xl
    `};
  }
`;
const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;
const LoadCases = styled.button`
  ${tw`
    py-1.5
    mx-0.5
    mb-0.5
    text-white
    tracking-megawide
    uppercase
    font-bold
    block
    bg-gray-700
    text-center
    w-full
  `};
  ${transition}
  &:hover,
  &:focus{
    ${tw`
      shadow-lg
      bg-brand-primary-700
  `};
`;
class Page extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      results: 1
    };
  }

  showMoreResults () {
    const { results } = this.state;
    this.setState({ results: results + 1 });
  }

  render () {
    const {
      data,
      location
    } = this.props;
    logger.gql(`this.state`, this.state);
    const { results } = this.state;
    logger.gql(data);
    logger.template(__filename, '>', 'render()');
    const siteTitle = data.site.siteMetadata.title;
    const cases = data.allContentfulCase.edges;
    const {
      title,
      description,
      heading,
      slug,
      fields
    } = data.contentfulPage;
    const { blocks } = fields;

    const seoProps = {
      title,
      description: description && description.childMdx.rawBody
    };
    const resultsGroup = 4 * results;
    const animationDuration = 600;
    logger.templateEnd();
    return (
      <Layout location={location} title={siteTitle}>
        <SEO {...seoProps} />
        <Menu colorScheme={`dark`} />
        <Fade>
          <IntroService
            bgColor={`gray-800`}
            label={title}
            intro={blocks[0].titleRich && blocks[0].titleRich.childMdx.rawBody}
            content={blocks[0].content && blocks[0].content.childMdx.body}
          />
        </Fade>
        <article>
          <CasesIntro>
            <h1>{blocks[1].titleRich && blocks[1].titleRich.childMdx.rawBody}</h1>
          </CasesIntro>
          <section>
            <Container fluid>
              <Row>
                {
                  cases && cases.map((edge, i) => {
                    const { node } = edge;
                    const props = {
                      title: node.title,
                      animationDuration,
                      animationDelay: (i > 2 ? i - 2.5 : i) * 200,
                      src: `https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/McKinsey%20Design/How%20we%20help%20clients/design_thumb-blue_491763594_1536x1536.ashx?mw=767&car=42:25`
                    };
                    if (i + 1 > resultsGroup) {
                      return ``;
                    }
                    return (
                      <CaseCard key={node.id} {...node} {...props} />
                    );
                  })
                }
                {
                  resultsGroup <= cases.length && (
                    <LoadCases onClick={() => this.showMoreResults()}>More cases</LoadCases>
                  )
                }
              </Row>
            </Container>
          </section>
          {/*
          {approachAreas && <CaseCard approachAreas={approachAreas} />}
            */}
          <CallToActionPreFooter
            bgColor={`gray-700`}
            title={`Have a challenge you’d like us to explore?`}
            callToAction={`Get in touch!`}
            uri={``}
            to={`/contact`}
            utm={{
              campaign: `t`
            }}
          />
        </article>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage( slug: { eq: $slug } ){
      ...Page
    }
    allContentfulCase ( sort: { fields: sort, order: ASC} ){
      edges{
        node{
          ...Case
        }
      }
    }
  }
`;
