import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import Color from 'color';
import colors from 'styles/Color';
import {
  Link
} from 'gatsby';

import { Container, Row } from 'components/GridSystem';

const Cover = styled.div`
  ${tw`
    flex
    mb-0
    top-0
    py-3
    md:py-0
    items-stretch
    content-center
    flex-wrap
    md:min-h-screen-60
  `};
  ${(props) => {
    const { background, bgColor } = props;
    const backgColorDefault = !bgColor ? `bg-gray-200` : bgColor;
    const backgroundColor = colors[backgColorDefault];
    const backgroundColorParse = Color(backgroundColor);
    const isLight = backgroundColorParse.isLight();
    return [
      !isLight ? tw`text-white` : tw`text-black`,
      backgroundColor && `background-color: ${backgroundColor}`,
      background && (background.image && `background-image: url('${background.image}');`),
      background && (background.size ? `background-size: ${background.size};` : `background-size: cover;`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`)
    ];
  }};
`;
const Content = styled.div`
  ${tw`
    flex-2
    max-w-full
    text-gray-300
  `};
`;

const Title = styled.h4`
  ${tw`
      text-3xl
      leading-tighter
      xl:leading-hero
      xl:text-hero
      font-hairline
  `};
  b{
    ${tw`font-bold`};
  }
`;
const Button = styled(Link)`
  ${tw`
    mb-0
    p-0
    text-3xl
    inline-block
    relative
    font-semibold
    tracking-tight
    xl:text-hero
    text-gray-200
    transition-all
    transition-400
    transition-ease
  `};
  background-color: transparent;
  &::after {
    ${tw`
      block
      absolute
      bg-brand-primary
      w-0
      mt-2
      transition-all
      transition-400
      transition-ease
    `};
    content: ' ';
    height: 2px;
  }
  &:hover,
  &:focus {
    ${tw`
      text-white
    `};
    &::after {
      ${tw`w-full`};
    }
  }
`;

export default class Component extends React.Component {
  render () {
    const {
      title,
      background,
      bgColor,
      callToAction,
      uri,
      to,
      utm
    } = this.props;
    const animationDuration = 600;
    return (
      <Cover background={background} bgColor={bgColor}>
        <Container>
          <Row>
            <Content>
              <Fade top duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <Title>
                  {title}
                </Title>
              </Fade>
              <Fade left duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <Button
                  to={`/contact`}
                >
                  {callToAction}
                </Button>
              </Fade>
            </Content>
          </Row>
        </Container>
      </Cover>
    );
  }
}

Component.propTypes = {
  title: PropTypes.string.isRequired,
  callToAction: PropTypes.string.isRequired,
  uri: PropTypes.string,
  to: PropTypes.string.isRequired,
  utm: PropTypes.shape({
    utm_source: PropTypes.string.isRequired,
    utm_medium: PropTypes.string.isRequired,
    utm_campaign: PropTypes.string.isRequired,
    utm_term: PropTypes.string,
    utm_content: PropTypes.string
  }).isRequired,
  children: PropTypes.node
};

Component.defaultProps = {
  uri: ``,
  children: ``
};
